import type { FC, ReactNode } from "react";

import { withAuthGuard } from "@/hocs/with-auth-guard";
import { HorizontalLayout } from "@/layouts/dashboard/layout/horizontal-layout/horizontal-layout";
import { paths } from "@/paths";
import type { NavItemConfig } from "@/types/nav";
import { buildRoute, useSessionStorageItem } from "src/utils/route";

interface LayoutProps {
  children?: ReactNode;
}

export const Layout: FC<LayoutProps> = withAuthGuard(({ children }) => {
  // If we have a WorksheetId in session storage, link directly to that worksheet when you click the nav link.
  const [worksheetId] = useSessionStorageItem("worksheetId");

  const items: NavItemConfig[] = [
    {
      key: "dashboard",
      title: "",
      items: [
        {
          key: "worksheet",
          title: "WORKSHEET",
          href: worksheetId
            ? buildRoute(paths.worksheets.worksheet, { worksheetId })
            : "", // If we don't have a worksheetId,
          // pass empty string to style as disabled.
          matcher: {
            type: "equals",
            href: paths.worksheets.worksheet,
          },
        },
        {
          key: "lab",
          title: "LAB",
          href: paths.labs.lab,
          matcher: {
            type: "equals",
            href: paths.labs.lab,
          },
        },
        {
          key: "settings",
          title: "",
          href: paths.settings.account,
          icon: "settings",
          matcher: {
            type: "startsWith",
            href: paths.settings.index,
          },
          tooltip: "Settings",
        },
        {
          key: "admin",
          title: "",
          href: paths.admin.overview,
          icon: "admin",
          matcher: {
            type: "startsWith",
            href: paths.admin.index,
          },
          allowedTypes: ["SUPERUSER"],
          tooltip: "Admin",
        },
      ],
    },
  ];
  return <HorizontalLayout items={items}>{children}</HorizontalLayout>;
});
