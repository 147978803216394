import { Compass } from "@phosphor-icons/react/dist/csr/Compass";
import { RocketLaunch } from "@phosphor-icons/react/dist/csr/RocketLaunch";
import { Gear as GearIcon } from "@phosphor-icons/react/dist/csr/Gear";
import { House as HouseIcon } from "@phosphor-icons/react/dist/csr/House";
import { Building } from "@phosphor-icons/react/dist/csr/Building";
import { Users } from "@phosphor-icons/react/dist/csr/Users";
import {
  type Icon,
  ShieldCheckered,
  UsersThree,
  Wrench,
  AddressBook,
  Brain,
  Path,
  Binoculars,
  Database,
  Sliders,
  Star,
} from "@phosphor-icons/react";

export const icons = {
  home: HouseIcon,
  campaign: RocketLaunch,
  guide: Compass,
  companies: Building,
  users: Users,
  admin: ShieldCheckered,
  settings: GearIcon,
  // Flow Icons
  items: Star,
  flow: Path,
  tracing: Binoculars,
  data: Database,
  sliders: Sliders,
  // Lab Icons
  skills: Brain,
  teams: UsersThree,
  tools: Wrench,
  guides: AddressBook,
} as Record<string, Icon>;
