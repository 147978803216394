import { type FC, type ReactNode, useEffect, useState } from "react";
import { useRouter } from "src/hooks/use-router";
import { paths } from "src/paths";
import { AuthContext } from "./auth-context";
import { Issuer } from "src/utils/auth";
import { type RouterInputs, type RouterOutputs, trpc } from "src/utils/trpc";
import { identifyUser } from "src/utils/amplitude";

const STORAGE_TOKEN_KEY = "accessToken";
const STORAGE_TENANT_KEY = "tenantId";

interface AuthProviderProps {
  children: ReactNode;
}

// App.tsx is loading this for now.
const accessToken = window.localStorage.getItem(STORAGE_TOKEN_KEY);
// const tenantId = window.localStorage.getItem(STORAGE_TENANT_KEY);

export const AuthProvider: FC<AuthProviderProps> = (props) => {
  const { children } = props;
  const router = useRouter();
  const [user, setUser] = useState<RouterOutputs["auth"]["me"]>();
  const [token, setToken] = useState<string>(accessToken ?? "");

  const signOut = async () => {
    // TODO: fix this after we solve App.tsx state
    localStorage.removeItem(STORAGE_TOKEN_KEY);
    localStorage.removeItem(STORAGE_TENANT_KEY);
    setUser(undefined);
    router.push(paths.auth.login);
  };

  const { mutateAsync: getSignIn } = trpc.auth.signin.useMutation();

  const signIn = async (
    loginType: RouterInputs["auth"]["signin"]["loginType"],
    email?: string,
  ): Promise<void> => {
    const { redirectUrl } = await getSignIn({
      loginType: loginType,
      email,
    });

    window.location.replace(redirectUrl);
  };

  const {
    data: me,
    error: meError,
    isInitialLoading,
  } = trpc.auth.me.useQuery(undefined, {
    enabled: token.length > 0,
  });
  useEffect(() => {
    if (me) {
      setUser(me);
      window.localStorage.setItem(STORAGE_TENANT_KEY, me.tenantId ?? "");
    }
  }, [me]);

  useEffect(() => {
    if (meError) {
      console.error("auth-provider: Failed to fetch current user", meError);
    }
  }, [meError]);

  const { mutateAsync: getSignInCallback } = trpc.auth.callback.useMutation();

  const signInCallback = async (code: string) => {
    const tokenAndProfile = await getSignInCallback({ code });

    if (tokenAndProfile && tokenAndProfile.accessToken) {
      setToken(tokenAndProfile.accessToken);
      window.localStorage.setItem(
        STORAGE_TOKEN_KEY,
        tokenAndProfile.accessToken,
      );
    }

    return tokenAndProfile;
  };

  const switchTenant = async (accessToken: string) => {
    setToken(accessToken);
    window.localStorage.setItem(STORAGE_TOKEN_KEY, accessToken);
    window.sessionStorage.clear();
    window.location.href = "/";
  };

  // Create the value for the auth context.
  const auth = {
    isInitialized: !token || !isInitialLoading,
    isAuthenticated: Boolean(user),
    user: user,
    token,
    issuer: Issuer.JWT,
    signIn,
    signInCallback,
    signOut,
    switchTenant,
  };

  // Keep Amplitude up-to-date on the user's email.
  useEffect(() => {
    if (auth.user?.email) {
      identifyUser(auth.user.email);
    }
  }, [auth.user?.email]);

  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};
