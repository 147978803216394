import type { FC, ReactNode } from "react";
import * as React from "react";

import { withAuthGuard } from "@/hocs/with-auth-guard";
import GlobalStyles from "@mui/material/GlobalStyles";
import { Seo } from "@/components/seo";
import { SideNav } from "@/layouts/dashboard/layout/vertical-layout/side-nav";
import { paths } from "@/paths";
import { useAuth } from "@/hooks/use-auth";
import { Box, Stack } from "@mui/material";

interface LayoutProps {
  children?: ReactNode;
}

interface LabLayoutContextProps {
  sideNavChildren?: ReactNode;
  setSideNavChildren: (children: ReactNode) => void;
}

export const LabLayoutContext = React.createContext<LabLayoutContextProps>({
  sideNavChildren: null,
  setSideNavChildren: () => {},
});

export const Layout: FC<LayoutProps> = withAuthGuard(({ children }) => {
  const [sideNavChildren, setSideNavChildren] = React.useState<ReactNode>(null);

  const auth = useAuth();

  return (
    <LabLayoutContext.Provider
      value={{
        sideNavChildren,
        setSideNavChildren,
      }}
    >
      <GlobalStyles
        styles={{
          body: {
            // "--MainNav-height": "56px",
            // "--MainNav-zIndex": 1000,
            "--SideNav-width": "240px",
            // "--SideNav-zIndex": 1100,
            // "--MobileNav-width": "240px",
            // "--MobileNav-zIndex": 1100,
          },
        }}
      />
      <Seo title="Lab" />
      <Stack>
        <SideNav
          color={"discrete"}
          items={[
            {
              key: "lab",
              title: "",
              items: [
                {
                  key: "skills",
                  title: "Skills",
                  icon: "skills",
                  href: paths.agents.index,
                  matcher: {
                    type: "startsWith",
                    href: paths.agents.index,
                  },
                },
                {
                  key: "teams",
                  title: "Teams",
                  icon: "teams",
                  href: paths.teams.index,
                  matcher: {
                    type: "startsWith",
                    href: paths.teams.index,
                  },
                },
                {
                  key: "tools",
                  title: "Tools",
                  icon: "tools",
                  href: paths.tools.index,
                  matcher: {
                    type: "startsWith",
                    href: paths.tools.index,
                  },
                },
                {
                  key: "guides",
                  title: "Guides",
                  href: paths.guides.index,
                  icon: "guides",
                  matcher: {
                    type: "startsWith",
                    href: paths.guides.index,
                  },
                },
              ],
            },
          ]}
          auth={auth}
        >
          {sideNavChildren}
        </SideNav>
      </Stack>
      <Box
        sx={{
          display: "flex",
          flex: "1 1 auto",
          flexDirection: "column",
          pl: "var(--SideNav-width)",
        }}
      >
        {children}
      </Box>
    </LabLayoutContext.Provider>
  );
});
